<template>


  <b-card-actions ref="list" :title="pageTitle" action-refresh @refresh="refresh">

    <div class="m-2">

      <!-- Table Top -->
      <b-row>

        <!-- Per Page -->
        <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <export-excel
              :data="rows"
              :columns="excel_columns"
              :file-name="'document_sections'"
              :file-type="'xlsx'"
              :sheet-name="'sheetname'"
          >
            <feather-icon icon="DownloadIcon"/>
          </export-excel>

          <b-button
              variant="primary"
              class="button-inline-spacing-2"
              @click="add_fulfillment()"
              :disabled="editDisabled"
          >
            <span class="text-nowrap">{{ add_label }}</span>
          </b-button>
        </b-col>

        <!-- Search -->
        <b-col
            cols="12"
            md="6"
        >

          <b-form-input
              v-model="searchTerm"
              :placeholder="$t('message.SearchPlaceholder')"
              type="text"
              class="d-inline-block"
          />
        </b-col>
      </b-row>
      <spacer height="30"/>
      <b-row v-if="false">

        <!-- Per Page -->
        <b-col
            cols="12"
            md="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"

        >
          <b-dropdown
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              :text="current_legal_regulation"
              variant="outline-primary"
              class="button-inline-spacing-2"
              block
          >
            <b-dropdown-item
                v-for="legal_regulation in legal_regulations"
                :key="legal_regulation.id"
                @click="setLegalRegulation(legal_regulation)"
            >
              <span class="ml-50">{{ legal_regulation.title }}</span>
            </b-dropdown-item>

          </b-dropdown>

        </b-col>
        <!-- Per Page -->
        <b-col
            cols="12"
            md="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            v-if="false"
        >
          <b-form-checkbox
              v-model="show_initial"
              name="check-button"
              switch
              inline
              @input="setInitial" v-ripple
          >
            {{ $t("Show initial fulfillments") }}
          </b-form-checkbox>

        </b-col>
        <b-col
            cols="12"
            md="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            v-if="false"
        >
          <b-form-checkbox
              v-model="show_periodic"
              name="check-button"
              switch
              inline
              @input="setPeriodic"
          >
            {{ $t("Show periodic fulfillments") }}
          </b-form-checkbox>

        </b-col>
        <b-col
            cols="12"
            md="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            v-if="false"
        >
          <b-form-checkbox
              v-model="show_only_open"
              name="check-button"
              switch
              inline
              @input="setOpen"
          >
            {{ $t("Show only open") }}
          </b-form-checkbox>

        </b-col>
      </b-row>

    </div>


    <!-- alert -->
    <!--b-alert
      variant="primary"
      show
    >
      <div class="alert-body">
      </div>
    </b-alert-->

    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">


    </div>

    <!-- table -->
    <vue-good-table
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
        :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
        :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >

      <!-- Slot: Table Row -->
      <template
          slot="table-row"
          slot-scope="props"
      >


        <!-- Column: Description -->
        <span
            v-if="props.column.field === 'description'"
            class="text-nowrap v-align-middle"
        >
          <span v-if="props.row && props.row.archived_at"> <del>{{ props.row.description }}</del></span>
        <span v-else>{{ props.row.description }}</span>
        </span>


        <!-- Column: Type -->
        <span
            v-if="props.column.field === 'fulfillment_type'"
            class="text-nowrap v-align-middle"
        >
          <span v-if="props.row && props.row.archived_at"> <del>{{ props.row.fulfillment_type }}</del></span>
        <span v-else>{{ props.row.fulfillment_type }}</span>
        </span>


        <!-- Column: Title -->
        <span
            v-else-if="props.column.field === 'frequency'"
            class="text-nowrap align-middle date"
        >
                    <span>{{ props.row.frequency }}</span>

        </span>


        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'" class="button-inline-spacing">

          <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="btn-icon"
              @click="edit(props.row.id)"

          >
      <feather-icon icon="Edit2Icon"/>
    </b-button>
             <b-button
                 v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                 variant="outline-primary"
                 class="btn-icon"
                 @click="copyToPeriodic(props.row.id)"
                 v-if="show_initial"
             >
      <feather-icon icon="CopyIcon"/>
    </b-button>

            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="btn-icon"
                @click="deleteFulfillment(props.row)"
                :disabled="editDisabled"
            >
      <feather-icon icon="TrashIcon"/>
    </b-button>


        </span>

        <span v-else class="text-nowrap align-middle">


        </span>
      </template>

      <!-- pagination -->
      <template
          slot="pagination-bottom"
          slot-scope="props"
      >

        <div class="d-flex justify-content-between flex-wrap">


          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              {{ $t('message.pagelength') }}
            </span>
            <b-form-select
                v-model="pageLength"
                :options="['3','5','10','15']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap">  {{ $t('message.of') }} {{ props.total }} {{ $t('message.pageText2') }} </span>
          </div>
          <div>

            <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>

            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>

    <template #code>
    </template>
  </b-card-actions>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {BCardActions} from "@core/components/b-card-actions";
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BAlert,
  BDropdown,
  BDropdownItem,
  BRow,
  BCol,
  BFormCheckbox
} from 'bootstrap-vue'
import {VueGoodTable} from 'vue-good-table'
import store from '@/store/index'
import {codeI18n} from './code'
import useJwt from '@/auth/jwt/useJwt'
import UserService from "@/basic/userService";
import EquipmentService from "@/basic/equipmentService";
import LegalService from "@/basic/legalService";
import Ripple from 'vue-ripple-directive'

import {BButton} from "bootstrap-vue";
import ExportExcel from "@/views/components/excel-export/ExportExcel";

import Vue from "vue";
import i18n from "@/libs/i18n";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import ability from "@/libs/acl/ability";
import moment from 'moment';

Vue.use(ExportExcel);

export default {
  components: {
    BCardCode,
    BCardActions,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BAlert,
    BDropdown,
    BDropdownItem,
    BButton,
    BRow,
    BCol,
    BFormCheckbox
  },
  directives: {
    Ripple,
  },
  props: {
    show_initial: {
      type: Boolean,
      default: false
    },
    show_periodic: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      pageLength: 15,
      codeI18n,
      dir: false,
      editDisabled: !ability.can("manage", "Basic HR"),
      columns: [
        {
          label: 'Description',
          field: 'description',
        }
        ,
        (this.show_periodic) ? {
          label: 'Frequency',
          field: 'frequency',
        } : {
          label: 'Type',
          field: 'fulfillment_type',
        },
        {
          label: 'action',
          field: 'action',
        },

      ],
      excel_columns: [
        {
          label: i18n.t('Description'),
          field: 'description',
        },
        {
          label: i18n.t('Legal Regulation'),
          field: 'legal_regulation',
        },
        {
          label: i18n.t('Type'),
          field: 'fulfillment_type',
        },


      ],
      rows: [],
      searchTerm: '',
      fulfillments: [],
      legal_regulations: [],
      current_legal_regulation: i18n.t('Legal Regulation'),
      current_legal_regulation_id: this.$route.params.legal_regulation_id,
      show_only_open: false,
    }
  },

  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: 'light-primary',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    pageTitle() {
      // if show_initial is true, return the i18n translation of "Initial fulfillments"
      // if show_periodic is true, return the i18n translation of "Periodic fulfillments"
      if (this.show_initial) {
        return i18n.t('Initial fulfillments') + " " + i18n.t('for') + " " + this.current_legal_regulation;
      } else if (this.show_periodic) {
        return i18n.t('Periodic fulfillments') + " " + i18n.t('for') + " " + this.current_legal_regulation;
      } else {
        return i18n.t('Fulfillments')
      }
    },

    add_label() {
      if (this.show_initial) {
        return i18n.t('Add Initial fulfillment')
      } else if (this.show_periodic) {
        return i18n.t('Add Periodic fulfillment')
      } else {
        return i18n.t('Add fulfillment')
      }
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  methods: {
    refresh() {
      console.log("refresh")
      this.$refs["list"].showLoading = true

      const legalService = new LegalService(useJwt)
      legalService.fulfillments({}).then(response => {
        this.rows = response.data.data.fulfillments
        this.fulfillments = response.data.data.fulfillments
        console.log('rows', this.rows)
        this.$refs["list"].showLoading = false
        this.legal_regulations = response.data.data.legal_regulations
        console.log('looking for legal regulation', this.current_legal_regulation_id)
        console.log('looking for legal regulation in', this.legal_regulations)

        let legal_regulation = this.legal_regulations.find(legal_regulation => legal_regulation.id == this.current_legal_regulation_id);
        console.log('found ', legal_regulation)

        //this.setLegalRegulation(legal_regulation)
        this.setInitial(legal_regulation);
        this.setPeriodic(legal_regulation);
      })

    },


    getExpirationVariant(row) {
      const statusColor = {
        /* eslint-disable key-spacing */

        Future: 'light-success',
        Past: 'light-danger',
        Near: 'light-warning',
        /* eslint-enable key-spacing */
      }
      console.log('row', row)

      let today = moment();
      let expiration_datex = moment(row.expiration_date);

      let days_to_expiration = today.diff(expiration_datex, 'days') // -8 (days)
      console.log('days_to_expiration', days_to_expiration)

      if (days_to_expiration < -30)
        return statusColor["Future"]
      else if (days_to_expiration < -1)
        return statusColor["Near"]
      else
        return statusColor['Past']

    },
    add_fulfillment() {
      this.$router.push({
        name: 'fulfillment',
        params: {
          id: 'new',
          type: this.show_initial ? 'initial' : 'periodic',
          legal_regulation_id: this.current_legal_regulation_id
        }
      })
    },
    edit(id) {
      this.$router.push({name: 'fulfillment', params: {id: id, 'legal_regulation_id': this.current_legal_regulation_id}})
    },
    copyToPeriodic(row) {
      console.log('copyToPeriodic', row)

      this.$router.push({
        name: 'fulfillment',
        params: {
          id: 'new',
          copy_from: row,
          type: 'periodic',
          legal_regulation_id: this.current_legal_regulation_id
        }
      })
    },
    setOpen() {
      //this.searchTerm= legal_regulation.title
      this.$refs["list"].showLoading = true
      if (this.show_only_open) {
        this.rows = this.fulfillments.filter(item => (item.status === 'open'))
      } else {
        this.rows = this.fulfillments.filter(item => (item.status === 'open' || item.status === 'closed' || item.status === 'suspended' || item.status === 'to_be_started'))
      }
      this.$refs["list"].showLoading = false
    },
    setInitial(legal_regulation) {
      //this.searchTerm= legal_regulation.title
      this.$refs["list"].showLoading = true
      if (this.show_initial) {
        this.rows = this.fulfillments.filter(item => (item.fulfillment_type === 'initial'))
        this.rows = this.rows.filter(item => item.legal_regulation.id === legal_regulation.id)
      } else {
        this.rows = this.fulfillments.filter(item => (item.fulfillment_type === 'periodic'))
        this.rows = this.rows.filter(item => item.legal_regulation.id === legal_regulation.id)
      }
      this.$refs["list"].showLoading = false
    },
    setPeriodic(legal_regulation) {
      //this.searchTerm= legal_regulation.title
      this.$refs["list"].showLoading = true
      if (this.show_periodic) {
        this.rows = this.fulfillments.filter(item => (item.fulfillment_type === 'periodic'))
        console.log('periodic rows before', this.rows)
        this.rows = this.rows.filter(item => item.legal_regulation.id === legal_regulation.id)
        console.log('periodic rows after', this.rows)

      } else {
        this.rows = this.fulfillments.filter(item => (item.fulfillment_type === 'initial'))
        this.rows = this.rows.filter(item => item.legal_regulation.id === legal_regulation.id)
      }
      this.$refs["list"].showLoading = false
    },
    setLegalRegulation(legal_regulation) {
      //this.searchTerm= legal_regulation.title
      this.$refs["list"].showLoading = true
      console.log('setLegalRegulation', legal_regulation.id)
      console.log('setLegalRegulation fulfillments', this.fulfillments)
      this.rows = this.fulfillments.filter(item => item.legal_regulation.id === legal_regulation.id)
      console.log('setLegalRegulation rows', this.rows)

      this.current_legal_regulation = legal_regulation.title
      this.$refs["list"].showLoading = false

    },
    deleteFulfillment(row) {
      console.log('delete')
      let confirmed = false;
      let message = i18n.t('You are deleting the fulfillment') + ": \"" + row.description + "\". " + i18n.t("Are you sure?");

      this.$bvModal
          .msgBoxConfirm(message, {
            title: i18n.t('Confirmation Required'),
            size: 'sm',
            okVariant: 'primary',
            okTitle: i18n.t('Proceed'),
            cancelTitle: i18n.t('Cancel'),
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            confirmed = value

            if (confirmed) {
              const legalService = new LegalService(useJwt)


              legalService.delete_fulfillment(row.id).then(response => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: i18n.t('Fulfillment deleted'),
                    icon: 'AlertIcon',
                    variant: 'success',
                    text: i18n.t('Fulfillment deleted with success'),
                  },
                })
                this.refresh();
              })
            }
          })

    },
    list_documents(id) {
      this.$router.push({name: 'documents', params: {document_section_id: id}})
    },
  },
  mounted() {

    this.$refs["list"].showLoading = true

    this.unsubscribe = store.subscribe((mutation, state) => {
      if (mutation.type === 'selected_company') {
        console.log(`Updating selected_company to ${state.userCompany.id}`);
        this.refresh();
      }
    });
    this.refresh();

  },
  beforeDestroy() {
    this.unsubscribe();
  }
}
</script>
